<template>
  <Banner bg-image="yellow-bg.jpg" talk-img="support-group.png">
    <template v-slot:title>Find a support group</template>
    <template v-slot:subtitle>
      It can help to gain emotional support from others experiencing similar
      challenges.
    </template>
    <template v-slot:description>
      Here are some support groups you can reach out to.
    </template>
  </Banner>
  <section class="pt-8">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold text-center md:text-3xl text-blue">
        Miskanawah Programs
      </div>
      <div class="p-8 mt-8 bg-blue-100">
        <div class="p-8 border-b-2 border-white-100">
          <div class="text-xl font-bold text-blue md:text-2xl">
            Diamond Willow Youth Lodge
          </div>
        </div>
        <div class="m-8 text-xl font-bold text-blue md:text-2xl">
          <div>
            Oskâpêwis Youth Program
            <div class="pt-2.5 font-normal">Cree for “worthy helper”</div>
          </div>
        </div>
      </div>
      <div class="p-8 mx-auto max-w-7xl md:px-14">
        <a
          href="https://miskanawah.ca/youth-programs"
          class="text-green md:mx-8"
          ><u>Learn more about these Miskanawah programs</u></a
        >
      </div>
    </div>
  </section>

  <!-- MORE PROGRAMS -->
  <section class="py-10 gradient-white group-block md:p-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        More Programs
      </div>
      <ul class="about-ul">
        <li>
          <a href="https:usay.ca/" class="text-green"
            ><u> Urban Society for Aboriginal Youth</u></a
          >
        </li>
        <li>
          <a
            href="https://www.growwithtrellis.ca/youth-programs/"
            class="text-green"
            ><u>The Iiyika’kimaat Program at the Trellis Society </u></a
          ><br />
          (The word Iiyika’kimaat means “to try hard” in Blackfoot.)
        </li>
        <li>
          <a href="https://afccalgary.org/" class="text-green"
            ><u>Aboriginal Friendship Centre</u></a
          >
        </li>
        <li>
          <a href="https://miskanawah.ca/family-programs" class="text-green"
            ><u>Tawaw Family Resource Networks</u></a
          >
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "FindASupportGroup",
  components: {
    Banner,
  },
};
</script>

<style scoped>
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}
ul.about-ul li {
  padding: 8px 0 8px 50px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}
ul.about-ul li::after {
  content: "";
  background: #22b46e;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  top: 15px;
}
</style>
